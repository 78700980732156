import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

// Bank Details
export function getBankDetails() {
  return api.get('/spa/settings/bank-details/data', {})
}

export function createBankDetail(data) {
  return api.post('/spa/settings/bank-details/create', data)
}

export function editBankDetail(data) {
  return api.post('/spa/settings/bank-details/edit', data)
}

export function deleteBankDetail(bank_details_id) {
  return api.post('/spa/settings/bank-details/delete', {bank_details_id})
}

// Assets
export function getAssets() {
  return api.get('/spa/select-list-data', {name: 'base_asset'})
}

export function getSettingsAssets() {
  return api.get('/spa/settings/assets/data', {})
}

export function createAsset(data) {
  return api.post('/spa/settings/assets/create', data)
}

export function updateAsset(data) {
  return api.post('/spa/settings/assets/edit', data)
}

export function deleteAsset(asset_id) {
  return api.post('/spa/settings/assets/delete', {asset_id})
}

// Departments and Teams
export function getDepartments() {
  return api.get('/spa/settings/departments/data', {})
}

export function createDepartment(data) {
  return api.post('/spa/settings/departments/create', data)
}

export function editDepartment(data) {
  return api.post('/spa/settings/departments/edit', data)
}

export function deleteDepartment(department_id) {
  return api.post('/spa/settings/departments/delete', {department_id})
}

export function getTeam(id) {
  return api.get(`/spa/settings/departments/team/show/${id}`, {})
}

export function createTeam(data) {
  return api.post('/spa/settings/departments/team/create', data)
}

export function editTeam(data) {
  return api.post('/spa/settings/departments/team/edit', data)
}

export function deleteTeam(team_id) {
  return api.post('/spa/settings/departments/team/delete', {team_id})
}

// Documents
export function getDocumentTypes() {
  return api.get('/spa/settings/document-types/data', {})
}

export function createDocument(data) {
  return api.post('/spa/settings/document-types/create', data)
}

export function editDocument(data) {
  return api.post('/spa/settings/document-types/edit', data)
}

export function deleteDocument(document_type_id) {
  return api.post('/spa/settings/document-types/delete', {document_type_id})
}

// Recipients
export function getRecipients() {
  return api.get('/spa/settings/recipients/data', {})
}

export function createRecipient(data) {
  return api.post('/spa/settings/recipients/create', data)
}

export function editRecipient(data) {
  return api.post('/spa/settings/recipients/edit', data)
}

export function deleteRecipient(recipient_id) {
  return api.post('/spa/settings/recipients/delete', {recipient_id})
}

// Brands
export function getBrands() {
  return api.get('/spa/settings/brands/data', {})
}

export function createBrand(data) {
  return api.post('/spa/settings/brands/create', data)
}

export function editBrand(data) {
  return api.post('/spa/settings/brands/edit', data)
}

export function deleteBrand(brand_id) {
  return api.post('/spa/settings/brands/delete', {brand_id})
}

// Configurations
export function getConfigurations() {
  return api.get('/spa/settings/configurations/data', {})
}

export function getConfiguration(id) {
  return api.get(`spa/settings/configurations/get/${id}`, {})
}

export function createConfig(data) {
  return api.post('/spa/settings/configurations/create', data)
}

export function updateConfig(data) {
  return api.post('/spa/settings/configurations/update', data)
}

export function deleteConfig(configuration_id) {
  return api.post('/spa/settings/configurations/delete', {configuration_id})
}

// Templates
export function getTemplates() {
  return api.get(`/spa/settings/templates/data`, {})
}

export function getTemplate(id, type) {
  return api.get(`/spa/settings/templates/get/${id}`, {type})
}

export function createTemplate(data) {
  return api.post('/spa/settings/templates/create', data)
}

export function updateTemplate(data) {
  return api.post(`/spa/settings/templates/update`, data)
}

export function deleteTemplate(id) {
  return api.post(`/spa/settings/templates/delete/${id}`, {})
}

// Customer Statuses Groups
export function createStatusGroup(data) {
  return api.post('/spa/settings/status-groups/create', data)
}

export function getStatusGroups() {
  return api.get('/spa/settings/status-groups/data', {})
}

export function editStatusGroup(data) {
  return api.post('/spa/settings/status-groups/edit', data)
}

export function deleteStatusGroup(id) {
  return api.post(`spa/settings/status-groups/delete/${id}`, {})
}

// VoIP
export function createVoipAccount(data) {
  return api.post(`/spa/settings/voip-account/create`, data)
}

export function updateVoipAccount(id, data) {
  return api.post(`/spa/settings/voip-account/update/${id}`, data)
}

export function deleteVoipAccount(id) {
  return api.post(`/spa/settings/voip-account/delete/${id}`, {})
}
