import React, {useContext, useEffect, useState} from "react"
import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import {deleteTemplate, getTemplates} from "../../api/settings"
import AddTemplate from "./Modals/AddTemplate"
import {ModalContext} from "../../contexts"
import {toast} from "react-toastify"
import EditTemplate from "./Modals/EditTemplate"

function templateTypes() {
  return [
    {
      title: 'Email',
      type: 'email',
      fields: [
        {label: 'Brand', type: 'select', selectListDataName: 'brands', attrs: {name: 'brand[]'}},
        {label: 'Subject', type: 'input', attrs: {name: 'subject'}},
        {label: 'Template', type: 'input', attrs: {name: 'template', type: 'textarea', rows: 10}},
      ]
    },
    {
      title: 'Transaction comment',
      type: 'transaction_comment',
      fields: [
        {label: 'Departments', type: 'select', selectListDataName: 'departments', isMulti: true, attrs: {name: 'departments[]',}},
        {label: 'Template', type: 'input', attrs: {name: 'template', type: 'textarea', rows: 10}},
      ]
    },
    {
      title: 'Ticket answer',
      type: 'ticket_answer',
      fields: [
        {label: 'Departments', type: 'select', selectListDataName: 'departments', isMulti: true, attrs: {name: 'departments[]',}},
        {label: 'Template', type: 'input', attrs: {name: 'template', type: 'textarea', rows: 10}},
      ]
    },
  ]
}

const Templates = () => {
  const {openModal} = useContext(ModalContext)
  const [templates, setTemplates] = useState([])

  // Permissions
  const canCreateTemplate = useUserCan('create_template')
  const canUpdateTemplate = useUserCan('edit_template')
  const canDeleteTemplate = useUserCan('delete_template')

  const getTemplatesData = () => {
    getTemplates().then(r => setTemplates(r))
  }

  const addTemplateHandler = () => {
    openModal({
      title: "Add Template",
      content: <AddTemplate updateTable={getTemplatesData} templateTypes={templateTypes()}/>,
      size: 'lg'
    })
  }

  const editTemplateHandler = (id, type) => {
    openModal({
      title: "Edit Template",
      content: <EditTemplate id={id} type={type} updateTable={getTemplatesData} templateTypes={templateTypes()}/>,
      size: 'lg'
    })
  }

  const deleteTemplateHandler = (id) => {
    if (confirm('Are you sure to delete this configuration?')) {
      deleteTemplate(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getTemplatesData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  useEffect(() => {
    getTemplatesData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-end mt-1 mb-3">
        {canCreateTemplate &&
          <div className="d-flex justify-content-end">
            <Button color="primary" className="btn" onClick={addTemplateHandler}>
              Add Template
            </Button>
          </div>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-nowrap">
          <thead>
          <tr className="border-bottom">
            <th>ID</th>
            <th>NAME</th>
            <th>BRAND</th>
            <th>DEPARTMENTS</th>
            <th>TYPE</th>
            {(canUpdateTemplate || canDeleteTemplate) &&
              <th className="col-1" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {templates.length > 0 ? templates.map((template, idx) =>
            <tr key={idx}>
              <td>{template.id}</td>
              <td>{template.name ?? '-'}</td>
              <td>{template?.brands?.map(i => i.short_name).join(', ') ?? '-'}</td>
              <td>{template?.departments?.map(i => i.name).join(', ') ?? '-'}</td>
              <td>{template.type.capitalize().replace('_', ' ') ?? '-'}</td>
              {(canUpdateTemplate || canDeleteTemplate) &&
                <td>
                  <div className="hstack gap-3 flex-wrap">
                    {canUpdateTemplate &&
                      <Link
                        to="#"
                        onClick={() => editTemplateHandler(template.id, template.type)}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                    {canDeleteTemplate &&
                      <Link
                        to="#"
                        onClick={() => deleteTemplateHandler(template.id)}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                  </div>
                </td>}
            </tr>) :
            (<tr>
              <td colSpan={6} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default Templates
