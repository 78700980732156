import {Button} from "reactstrap"
import React, {useState} from "react"
import {navigateNextCustomer} from "../../../../api/customers"
import {toast} from "react-toastify"
import {useNavigate} from "react-router-dom";

const AdditionalMenu = ({customerId}) => {
  const navigate = useNavigate()
  const [showAdditionalMenu, setShowAdditionalMenu] = useState(false)

  const navigateToNextCustomerHandler = (dir) => {
    let data = {
      customer_id: customerId,
      dir,
    }
    let queryString = JSON.parse(localStorage.getItem('table-filters'))

    if (queryString) {
      queryString = Object.values(queryString.customers_filter)
      queryString.forEach(i => {
        let key = i.name + '[name]'
        let prefix = i.name

        if (data.hasOwnProperty(key)) {
          prefix = prefix + '_1'
        } else {
        }

        data[prefix + '[name]'] = i.name
        if (i.operator) {
          data[prefix + '[operator]'] = i.operator
        }

        if (typeof i.value === 'object') {
          if (Array.isArray(i.value)) {
            data[prefix + '[value]'] = i.value.flatMap(item => item.value ?? item)
          } else {
            data[prefix + '[value]'] = i.value.value
          }
        } else {
          data[prefix + '[value]'] = i.value
        }
      })
    }

    navigateNextCustomer(data).then(r => {
      if (r.success) {
        navigate(`/customers/show/${r.next_customer_id}`)
      } else {
        toast.info(r.message)
      }
    })
  }

  return (
    <div className="fixedBottomRow">
      <div
        className={(showAdditionalMenu ? '' : 'd-none') +
          ' col-12 p-1 mb-1 d-flex justify-content-center bg-soft-success'}
      >
        <div className="pe-1">
          <Button
            className="btn-label btn-soft-success btn-sm"
            onClick={() => navigateToNextCustomerHandler('previous')}
          >
            Previous
            <i className="ri-arrow-left-circle-line label-icon align-middle fs-16 me-2"></i>
          </Button>
        </div>
        <div className="ps-1">
          <Button
            className="btn-label right btn-soft-success btn-sm"
            onClick={() => navigateToNextCustomerHandler('next')}
          >
            Next
            <i className="ri-arrow-right-circle-line label-icon align-middle fs-16 ms-2"></i>
          </Button>
        </div>
      </div>

      <div className="col-12 text-center">
        <Button
          className={`btn-sm btn-${showAdditionalMenu ? 'success' : 'soft-success'}`}
          onClick={() => setShowAdditionalMenu(!showAdditionalMenu)}
        >
          <i className={`bx bx-chevrons-${showAdditionalMenu ? 'down' : 'up'} fs-16 text-white mx-3`}></i>
        </Button>
      </div>
    </div>
  )
}

export default AdditionalMenu
