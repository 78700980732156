import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export function deleteInvoice(id) {
  return api.post('/spa/invoice/delete', {invoice_id: id})
}

export function getInvoice(id) {
    return api.get(`/spa/invoice/show/${id}`, {})
}

export function createInvoice(data) {
  return api.post('/spa/invoice/create', data)
}

export function updateInvoice(data) {
  return api.post('/spa/invoice/update', data)
}

export function getUuid() {
  return api.get('/spa/invoice/get-uuid', {})
}
