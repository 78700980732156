import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, Col, Container, Row, Spinner, Table} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Link, useParams, useNavigate} from "react-router-dom"
import {getInvoice} from "../../api/invoices"
import {prettyDate, toDateFormat} from "../../helpers/utils"
import {getProfile} from "../../api/customers"
import moment from "moment"
import {getBrands} from "../../api/settings"

const InvoiceDetails = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [invoiceData, setInvoiceData] = useState({})
  const [profileData, setProfileData] = useState({})
  const [brand, setBrand] = useState([])

  const dueDate = toDateFormat(invoiceData.due_date)
  const invoiceDate = toDateFormat(invoiceData.invoicing_date)

  const dueDateFormatted = moment(dueDate, 'YYYY-MM-DD');
  const invoiceDateFormatted = moment(invoiceDate, 'YYYY-MM-DD');
  const termsDays = dueDateFormatted.diff(invoiceDateFormatted, 'days')

  const getInvoiceData = () => {
    getInvoice(id).then((r) => {
      setInvoiceData(r)
      getProfileData(r.customer_id)
    })
  }

  const getBrandData = () => {
    getBrands().then(r => {
      setBrand(r.find(i => String(invoiceData.mail_from) === String(i.id)))
    })
  }

  const getProfileData = (id) => {
    getProfile(id || invoiceData.customer_id).then((r) => {
      setProfileData(r)
    })
  }

  const printInvoice = () => {
    window.print()
  }

  useEffect(() => {
    getInvoiceData()
  }, [])

  useEffect(() => {
    getBrandData()
  }, [invoiceData])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Invoice"/>
          <Row className="d-flex justify-content-center">
            <Col md={12} lg={10} xl={8} xxl={6}>
              <Card>
                <CardBody>
                  {/* Header */}
                  <Row className="row d-flex justify-content-between mt-3 mb-2">
                    <Col xs={12} sm={6} md={6} lg={6} className="flex-column align-items-center mb-3">
                      {brand ?
                        <div className="d-flex flex-column">
                          <img
                            src={`/storage/${brand?.logo_path}`}
                            alt={brand?.title}
                            width="190"
                            className="img-fluid pb-1"
                          />
                          <h3>{brand?.title}</h3>
                          <p className="fs-16 text-muted mb-0">
                            {brand?.address}
                          </p>
                        </div> :
                        invoiceData.mail_from === "custom" ? (
                            <div className="mb-3">
                              <img
                                src={invoiceData.meta.custom_from ? `/storage/${invoiceData.meta.custom_from.logo}` : ''}
                                alt="brand_logo"
                                width="200"
                                className="img-fluid pt-2 pb-1"
                              />
                              <h5>{invoiceData.meta.custom_from.custom_name}</h5>
                              <p className="fs-16 text-muted mb-1">
                                {invoiceData.meta.custom_from.custom_address &&
                                  invoiceData.meta.custom_from.custom_address.split(/\r?\n/)
                                    .map((string, idx) => (
                                      <React.Fragment key={idx}>
                                        <span>{string}</span> <br/>
                                      </React.Fragment>
                                    ))
                                }
                              </p>
                              <p className="fs-16 text-muted">
                                {invoiceData.meta.custom_from.custom_info}
                              </p>
                            </div>
                          ) :
                          (
                            <div className="d-flex justify-content-center">
                              <Spinner className="flex-shrink-0"></Spinner>
                            </div>
                          )
                      }

                      <div>
                        <h5 className="mb-1">Bill to:</h5>
                        <p className="fs-16">{profileData?.data?.name || 'Loading...'}</p>
                      </div>
                    </Col>
                    <Col
                      xs={12} sm={6} md={6} lg={6}
                      className="d-flex flex-column"
                    >
                      <Table responsive className="mt-3 fs-14">
                        <tbody>
                        <tr className="border-top">
                          <td><strong>Invoice Id:</strong></td>
                          <td className="text-end">{invoiceData.uniq_id}</td>
                        </tr>
                        <tr>
                          <td><strong>Invoicing date:</strong></td>
                          <td className="text-end">{prettyDate(invoiceDate, false)}</td>
                        </tr>
                        <tr>
                          <td><strong>Due date:</strong></td>
                          <td className="text-end">{prettyDate(dueDate, false)}</td>
                        </tr>
                        <tr>
                          <td><strong>Terms:</strong></td>
                          <td className="text-end">
                            {termsDays === 1 ? `${termsDays} day` : `${termsDays} days`}
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  {/* Items and summary */}
                  <Row className="d-flex justify-content-end">
                    <Col xs={12} className="mb-3">
                      <Table responsive>
                        <thead>
                        <tr className="table-active border-bottom">
                          <th className="col-6">ITEM</th>
                          <th className="col-2 text-center">QUANTITY</th>
                          <th className="col-2 text-center">RATE</th>
                          <th className="col-2 text-center">AMOUNT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(invoiceData.items || []).map((row, idx) => (
                          <tr key={idx} className="border-bottom">
                            <td className="fw-medium">{row.product_description}</td>
                            <td className="text-center">{row.quantity}</td>
                            <td className="text-center">
                              {Number(row.rate).toFixed(2)} {invoiceData.formatted_code}
                            </td>
                            <td className="text-center table-active">
                              {Number(row.amount).toFixed(2)} {invoiceData.formatted_code}
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} className="mb-3">
                      <Table>
                        <tbody>
                        <tr className="fw-medium">
                          <td>Sub Total:</td>
                          <td className="text-end">
                            {Number(invoiceData.subtotal_amount).toFixed(2)} {invoiceData.formatted_code}
                          </td>
                        </tr>
                        <tr>
                          <td>Discount:</td>
                          <td className="text-end">{invoiceData.discount ? invoiceData.discount : '0'}%</td>
                        </tr>
                        <tr>
                          <td>Tax:</td>
                          <td className="text-end">{invoiceData.tax ? invoiceData.tax : '0'}%</td>
                        </tr>
                        <tr className="table-active">
                          <td className="fw-bold">Total:</td>
                          <td className="text-end fw-bold">
                            {Number(invoiceData.total_amount).toFixed(2)} {invoiceData.formatted_code}
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  {/* Buttons */}
                  <Row className="mb-3 d-print-none">
                    <div className="d-flex justify-content-between">
                      <Button color="primary" className="btn" onClick={() => navigate(-1)}>
                        Back
                      </Button>
                      <Link to="#" onClick={printInvoice} className="btn btn-success">
                        <i className="ri-printer-line align-bottom me-1"></i>
                        Print
                      </Link>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoiceDetails
