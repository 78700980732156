import React, {useEffect} from "react"
import {Navigate, Route} from "react-router-dom"
import {setAuthorization} from "../helpers/api_helper"

import {useLockScreen, useProfile} from "../Components/Hooks/UserHooks"

import {logoutUser} from "../slices/auth/login/thunk"

import {createEchoInstance} from '../ws'

// Redux
import {useDispatch} from "react-redux"
import {setCustomerData} from "../slices/state/reducer"
import {setUnreadTickets} from "../slices/tickets/reducer"
import {setCryptoRates} from "../slices/crypto-rates/reducer";
import {setNotifications} from "../slices/notification/reducer";

let echoEventsConfState = false

function echoEventsConf(userId) {
  const dispatch = useDispatch()
  if (echoEventsConfState) return

  let echo = createEchoInstance()

  echo.channel('general')
    .listen('CustomerOnlineStatus', (data) => {
      dispatch(setCustomerData(data))
    })
    .listen('ChangeDashboardRates', (r) => {
      dispatch(setCryptoRates(r))
    })

  echo.private(`notification.${userId}`)
    .listen('UserNotification', (r) => {
      dispatch(setNotifications(r))
    })
    .listen('TicketUpdated', (data) => {
      dispatch(setUnreadTickets(data?.ticket_id))
    })

  echoEventsConfState = true
}

const AuthProtected = (props) => {
  const dispatch = useDispatch()
  const {userProfile, loading, token} = useProfile()
  const {lockScreen} = useLockScreen()

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token)
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser())
    }
  }, [token, userProfile, loading, dispatch])

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading && !token) {
    return (
      <Navigate to={{pathname: "/login", state: {from: props.location}}}/>
    )
  }

  // if (lockScreen) {
  //   return (
  //     <Navigate to={{pathname: "/pin-code", state: {from: props.location}}}/>
  //   )
  // }

  if (userProfile?.id) {
    echoEventsConf(userProfile.id)
  }

  return <>{props.children}</>
};

const AccessRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>)
      }}
    />
  );
};

export {AuthProtected, AccessRoute}
