import {Button, Form, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import Select from "react-select"
import {ModalContext} from "../../../contexts"
import {getTemplate, updateTemplate} from "../../../api/settings"
import {selectListData} from "../../../api/general"
import {toast} from "react-toastify"
import CustomSelect from "../../../Components/Form/CustomSelect";
import CustomInput from "../../../Components/Form/CustomInput";

const EditTemplate = ({id, type, updateTable, templateTypes}) => {
  const {closeModal} = useContext(ModalContext)
  const [name, setName] = useState('')

  const [renderedFields, setRenderedFields] = useState('')

  const getTemplateData = () => {
    getTemplate(id, type).then(r => {
      if (r.success) {
        let template = r.template
        setName(template.name)

        renderFields(template.type, template)
      } else {
        toast.error(r.message)
      }
    })
  }

  const renderFields = (type, templateData) => {
    let template = ''

    if (type) {
      template = templateTypes
        .find(i => i.type === type)
        .fields
        .map((field, idx) => {
          let currentRelatedIds;
          let currentValue = templateData.data[field.attrs?.name] ?? ''
          if (type === 'email') {
            currentRelatedIds = templateData?.brands ?? []
          } else if (['ticket_answer', 'transaction_comment'].includes(type)) {
            currentRelatedIds = templateData?.departments ?? []
          }

          return (
            <div key={idx}>
              {field.type === 'select' &&
                <CustomSelect
                  params={field}
                  value={currentValue}
                  label={field.label}
                  selectListDataName={field.selectListDataName}
                  relatedIds={currentRelatedIds}
                  attrs={field.attrs}
                />
              }

              {field.type === 'input' &&
                <CustomInput
                  value={currentValue}
                  label={field.label}
                  attrs={field.attrs}
                />
              }
            </div>
          )
        })
    }

    setRenderedFields(template)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)
    data.append('template_id', id)
    data.append('type', type)

    updateTemplate(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
      } else {
        toast.error(r.message)
      }
    }).finally(() => closeModal())
  }

  useEffect(() => {
    getTemplateData()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Name</Label>
          <Input
            name="name"
            required={true}
            placeholder="Enter name"
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {renderedFields}

        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Update</Button>
        </div>
      </Form>
    </>
  )
}

export default EditTemplate
