import {cryptoRates} from '../../api/widget'
import {setCryptoRates} from "./reducer"

export const getCryptoRatesData = () => async (dispatch) => {
  try {
    let response

    response = await cryptoRates()

    if (response) {
      dispatch(setCryptoRates(response))
    }
  } catch (error) {
    console.log(error)
  }
}
