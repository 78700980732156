import {APIClient} from "../helpers/api_helper"
import {selectListData} from "./general";

const api = new APIClient();

export function getTransaction(id) {
    return api.get(`/spa/transactions/show/${id}`, {})
}

export function changeStatus(status, transaction_ids) {
    return api.post('/spa/transactions/change-status', {status, transaction_ids})
}

export function changeIsReal(status, transfer_id) {
    return api.post('/spa/transactions/change-is-real', {type: 'real', status, transfer_id})
}

export function changeIsSync(status, transfer_id) {
    return api.post('/spa/transactions/change-is-real', {type: 'sync', status, transfer_id})
}

export function createTransaction(data) {
    return api.post('/spa/transactions/create', data)
}

export function updateTransaction(data) {
    return api.post('/spa/transactions/update', data)
}

export function deleteTransactions(transaction_ids) {
    return api.post('/spa/transactions/delete', {transaction_ids})
}

export function getTransactionsSides() {
    return selectListData('sides_for_modals')
}

export function getAllAssets() {
    return selectListData('all_assets')
}

export function getAssets() {
    return selectListData('base_asset')
}

export function getFiatAssets() {
    return selectListData('fiat_assets')
}

export function getTransactionsStatuses() {
    return selectListData('transaction_statuses')
}

export function calculateTransactionPrice(data) {
    return api.post('/spa/transactions/calculate-history-price', data)
}
