import classnames from "classnames";
import React, {useEffect} from "react";
import DateInput from "../FilterFields/DateInput";
import TextInput from "../FilterFields/TextInput";
import SelectFilterContainer from "./SelectFilterContainer";

function getInputTemplate(item, activeFilterData, filterStorageKey) {
  switch (item.type) {
    case 'select': {
      return (
        <SelectFilterContainer
          item={item}
          activeFilterData={activeFilterData}
          filterStorageKey={filterStorageKey}
        />
      )
    }
    case 'single_select': {
      return (
        <SelectFilterContainer
          item={item}
          activeFilterData={activeFilterData}
          filterStorageKey={filterStorageKey}
          canChangeOperator={false}
        />
      )
    }
    case 'date': {
      return (
        <div key={item.uuid} className="me-2">
          <label className="custom-label text-muted">
            {item.title}
            {/*<span className={classnames('ms-1 fs-11 fw-bold text-primary', {*/}
            {/*  'b-block': activeFilters.find(i => i.name === item.name),*/}
            {/*  'b-none': activeFilters.find(i => i.name === item.name)*/}
            {/*})}> {activeFilters.find(i => i.name === item.name)?.operator}</span>*/}
          </label>
          <DateInput
            // methodsRef={valuesMethodsRef}
            fieldData={item}
            rowValues={activeFilterData?.value}
            withoutPlaceholder={true}
            filterStorageKey={filterStorageKey}
          />
        </div>
      )
    }
    default: {
      return (
        <div key={item.uuid} className="me-2">
          <label className="custom-label text-muted">
            {item.title}
            {/*<span className={classnames('ms-1 fs-11 fw-bold text-primary', {*/}
            {/*  'b-block': activeFilters.find(i => i.name === item.name),*/}
            {/*  'b-none': activeFilters.find(i => i.name === item.name)*/}
            {/*})}> {activeFilters.find(i => i.name === item.name)?.operator}</span>*/}
          </label>
          <TextInput
            fieldData={item}
            rowValue={activeFilterData?.value}
            withoutPlaceholder={true}
            filterStorageKey={filterStorageKey}
          />
        </div>
      )
    }
  }
}

const FilterFields = ({filterFields, activeFiltersData, filterStorageKey}) => {
  return (filterFields.map((item, idx) => {

    let activeFilterData, inputTemplate;

    if (activeFiltersData) {
      activeFilterData = activeFiltersData[item.name]
    }

    inputTemplate = getInputTemplate(item, activeFilterData, filterStorageKey)

    return (
      <div key={idx} className={classnames('pt-0 mt-0 col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3', {
        'd-none': !item?.quick_filter,
        'd-block': item?.quick_filter
      })}>
        {inputTemplate}
      </div>
    )
  }))
}

export default FilterFields
