import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUserCan, useUserHasRole} from "../Components/Hooks/UserHooks";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isTransactions, setIsTransactions] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isCustomers, setIsCustomers] = useState(false);
    const [isEmployees, setIsEmployees] = useState(false);
    const [isTickets, setIsTickets] = useState(false);
    const [isCalendar, setIsCalendar] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isRoles, setIsRoles] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isPostbacks, setIsPostbacks] = useState(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    // Permissions
    const canSeeCustomers = useUserCan(['view_customers', 'view_customer'])
    const canSeeTransactions = useUserCan(['view_all_transactions', 'view_transactions'])
    const canSeeInvoices = useUserCan(['view_all_invoices', 'view_invoices'])
    const canSeeEmployees = useUserCan(['view_users', 'view_user'])
    const canSeeTickets = useUserCan(['view_all_tickets', 'view_tickets'])
    const canSeeSettings = useUserCan('view_settings')
    const canSeeRoles = useUserHasRole('super_admin')
    const canSeeImport = useUserCan('import_customers')
    const canSeePostback = useUserCan('view_postbacks')

    function updateIconSidebar(e) {
      if (e && e.target && e.target.getAttribute("subitems")) {
        const ul = document.getElementById("two-column-menu");
        const iconItems = ul.querySelectorAll(".nav-icon.active");
        let activeIconItems = [...iconItems];
        activeIconItems.forEach((item) => {
          item.classList.remove("active");
          let id = item.getAttribute("subitems");
          if (document.getElementById(id))
            document.getElementById(id).classList.remove("show");
        });
      }
    }

    useEffect(() => {
      document.body.classList.remove('twocolumn-panel');
      if (isCurrentState !== 'Dashboard') {
        setIsDashboard(false);
      }
      if (isCurrentState !== 'AdminCustomers') {
        setIsCustomers(false);
      }
    }, [
      history,
      isCurrentState,
      isDashboard,
      isCustomers,
    ]);

    const menuItems = [
      {
        label: "Menu",
        isHeader: true,
      },
      {
        id: "dashboard",
        label: "Dashboard",
        icon: "ri-bank-line",
        link: "/dashboard",
        stateVariables: isDashboard,
        click: function (e) {
          e.preventDefault();
          setIsDashboard(!isDashboard);
          setIsCurrentState('Dashboard');
          updateIconSidebar(e);
        },
      },
      {
        id: "customers",
        label: "Customers",
        icon: "ri-file-user-line",
        link: "/customers",
        stateVariables: isCustomers,
        visible: canSeeCustomers,
        click: function (e) {
          e.preventDefault();
          setIsCustomers(!isCustomers);
          setIsCurrentState('Customers');
          updateIconSidebar(e);
        },
      },
      {
        id: "transactions",
        label: "Transactions",
        icon: "ri-exchange-dollar-fill",
        link: "/transactions",
        stateVariables: isTransactions,
        visible: canSeeTransactions,
        click: function (e) {
          e.preventDefault();
          setIsTransactions(!isTransactions);
          setIsCurrentState('Transactions');
          updateIconSidebar(e);
        },
      },
      {
        id: "invoices",
        label: "Invoices",
        icon: "ri-currency-line",
        link: "/invoices",
        stateVariables: isInvoices,
        visible: canSeeInvoices,
        click: function (e) {
          e.preventDefault();
          setIsInvoices(!isInvoices);
          setIsCurrentState('Invoices');
          updateIconSidebar(e);
        },
      },
      {
        id: "employees",
        label: "Employees",
        icon: "ri-creative-commons-by-line",
        link: "/employees",
        stateVariables: isEmployees,
        visible: canSeeEmployees,
        click: function (e) {
          e.preventDefault();
          setIsEmployees(!isEmployees);
          setIsCurrentState('Employees');
          updateIconSidebar(e);
        },
      },
      {
        id: "tickets",
        label: "Tickets",
        icon: "ri-file-unknow-line",
        link: "/tickets",
        stateVariables: isTickets,
        visible: canSeeTickets,
        click: function (e) {
          e.preventDefault();
          setIsTickets(!isTickets);
          setIsCurrentState('Tickets');
          updateIconSidebar(e);
        },
        subItems: [
          {
            id: "tickets_list",
            label: "List",
            link: "/tickets/list",
            parentId: "transactions"
          },
          {
            id: "chats",
            label: "Chats",
            link: "/tickets/chats",
            parentId: "transactions"
          }
        ]
      },
      {
        id: "calendar",
        label: "Calendar",
        icon: "ri-calendar-line",
        link: "/calendar",
        stateVariables: isCalendar,
        click: function (e) {
          e.preventDefault();
          setIsSettings(!isCalendar);
          setIsCurrentState('Calendar');
          updateIconSidebar(e);
        },
      },
      {
        id: "settings",
        label: "Settings",
        icon: "ri-folder-settings-line",
        link: "/settings",
        stateVariables: isSettings,
        visible: canSeeSettings,
        click: function (e) {
          e.preventDefault();
          setIsSettings(!isSettings);
          setIsCurrentState('Settings');
          updateIconSidebar(e);
        },
      },
      {
        id: "roles",
        label: "Roles",
        icon: "ri-send-to-back",
        link: "/roles",
        stateVariables: isRoles,
        visible: canSeeRoles,
        click: function (e) {
          e.preventDefault();
          setIsRoles(!isRoles);
          setIsCurrentState('Roles');
          updateIconSidebar(e);
        },
      },
      {
        id: "import",
        label: "Import",
        icon: "ri-picture-in-picture-2-line",
        link: "/import",
        stateVariables: isImport,
        visible: canSeeImport,
        click: function (e) {
          e.preventDefault();
          setIsImport(!isImport);
          setIsCurrentState('Import');
          updateIconSidebar(e);
        },
      },
      {
        id: "postback",
        label: "Postbacks",
        icon: "ri-picture-in-picture-2-line",
        link: "/postbacks",
        stateVariables: isPostbacks,
        visible: canSeePostback,
        click: function (e) {
          e.preventDefault();
          setIsPostbacks(!isPostbacks);
          setIsCurrentState('Postback');
          updateIconSidebar(e);
        },
        subItems: [
          {
            id: "postbacks",
            label: "List",
            link: "/postbacks/list",
            parentId: "postbacks"
          },
          {
            id: "chats",
            label: "History",
            link: "/postbacks/history",
            parentId: "postbacks"
          }
        ],
      },
    ];

    return <React.Fragment>{menuItems.filter(i => {
      return i?.visible !== false;
    })}</React.Fragment>;
  }

export default Navdata;
